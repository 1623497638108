import { push } from "connected-react-router";
import * as routes from "../../Router/constants/routes";
import {
  USER_EMAIL_ONE,
  USER_EMAIL_TWO,
  USER_FULL_NAME,
  GOT_USER
} from "./types";

export const emailOneChanged = text => {
  //console.log("from action", text)
  return {
    type: USER_EMAIL_ONE,
    payload: text
  };
};

export const emailTwoChanged = text => {
  //console.log("from action", text)
  return {
    type: USER_EMAIL_TWO,
    payload: text
  };
};

export const userNameChanged = text => {
  //console.log("from action", text)
  return {
    type: USER_FULL_NAME,
    payload: text
  };
};

export const userReady = () => {
  return dispatch => {
    dispatch({ type: GOT_USER });
    dispatch(push(routes.SURVEY));
  }
}