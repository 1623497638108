import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  emailOneChanged,
  emailTwoChanged,
  userNameChanged,
  userReady
} from "../../store/actions/IdFormActions";
import * as routes from "../../Router/constants/routes";

class Id_Form extends Component {

  onEmailOneChange(text) {
    this.props.emailOneChanged(text);
  }

  onEmailTwoChange(text) {
    this.props.emailTwoChanged(text);
  }

  onUserNameChange(text) {
    this.props.userNameChanged(text);
  }

  onButtonPress = e => {
    e.preventDefault();
    this.props.userReady();
  };

  render() {
    const isInvalid =
      this.props.user_email_one !== this.props.user_email_two ||
      this.props.user_email_one === "" ||
      this.props.user_email_two === "" ||
      this.props.user_full_name === "" ||
      !this.props.user_email_one.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    const emailNoMatch =
      this.props.user_email_one !== this.props.user_email_two;

    const invalidEmail = !this.props.user_email_one.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    return (
      <div className="id-form-main animated lightSpeedIn">
        <span className="form-require">* = Required</span>
        <form onSubmit={this.onButtonPress.bind(this)}></form>
        <Link className="form-disclaimer" to={routes.DISCLAIMER}>Disclaimer</Link>
        <form onSubmit={this.onButtonPress.bind(this)}>
          <div className="form-group">
            <label className="id-form-label" htmlFor="emailOne">Email *</label>
            <input className="id-form-input"
              type="text"
              name="email1"
              required
              value={this.props.user_email_one}
              onChange={event => {
                this.onEmailOneChange(event.target.value);
              }}
            />
            {invalidEmail && this.props.user_email_one.length !== 0 ? (
              <span className="form-error">
                * Must Be A Valid Email Address
                        </span>
            ) : null}
          </div>
          <div className="form-group">
            <label className="id-form-label" htmlFor="emailTwo">Re-Type Email *</label>
            <input className="id-form-input"
              type="text"
              name="email2"
              required
              value={this.props.user_email_two}
              onChange={event => {
                this.onEmailTwoChange(event.target.value);
              }}
            />
            {emailNoMatch &&
              this.props.user_email_two.length !== 0 ? (
                <span className="form-error">
                  * Emails Do Not Match
                </span>
              ) : null}
          </div>
          <div className="form-group">
            <label className="id-form-label" htmlFor="fullName">Full Name *</label>
            <input className="id-form-input"
              type="text"

              name="full name"
              required
              value={this.props.user_full_name}
              onChange={event => {
                this.onUserNameChange(event.target.value);
              }}
            />
          </div>
          <input
            disabled={isInvalid}
            type="submit"
            value="Begin Survey"
            className="btn btn-primary btn-block "
          />
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ user_id_form, user_auth }) => {
  const { user_email_one, user_email_two, user_full_name } = user_id_form;

  return {
    user_email_one,
    user_email_two,
    user_full_name
  };
};

const mapDispatchToProps = dispatch => ({
  emailOneChanged: text => dispatch(emailOneChanged(text)),
  emailTwoChanged: text => dispatch(emailTwoChanged(text)),
  userNameChanged: text => dispatch(userNameChanged(text)),
  userReady: () => dispatch(userReady())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Id_Form));
