export default [
  {
    adj: "accurate",
    value: "C",
    most: false,
    least: false,
    def: "free from error or defect; consistent with a standard, rule, or model; precise; exact."
  },
  {
    adj: "adventurous",
    value: "D",
    most: false,
    least: false,
    def: "inclined or willing to engage in adventures; enjoying adventures."
  },
  {
    adj: "aggressive",
    value: "D",
    most: false,
    least: false,
    def: "vigorously energetic, especially in the use of initiative and forcefulness"
  },
  {
    adj: "agreeable",
    value: "S",
    most: false,
    least: false,
    def: "to one's liking; pleasing"
  },
  {
    adj: "ambitious",
    value: "D",
    most: false,
    least: false,
    def: "eagerly desirous of achieving or obtaining success, power, wealth, a specific goal, etc."
  },
  {
    adj: "amiable",
    value: "S",
    most: false,
    least: false,
    def: "agreeable; willing to accept the wishes, decisions, or suggestions of another or others; having or showing pleasant, good-natured personal qualities; affable"
  },
  {
    adj: "animated",
    value: "I",
    most: false,
    least: false,
    def: "full of life, action, or spirit; lively; vigorous"
  },
  {
    adj: "appealing",
    value: "I",
    most: false,
    least: false,
    def: "evoking or attracting interest, desire, curiosity, sympathy, or the like; attractive"
  },
  {
    adj: "argumentative",
    value: "D",
    most: false,
    least: false,
    def: "fond of or given to argument and dispute; disputatious; contentious"
  },
  {
    adj: "assertive",
    value: "D",
    most: false,
    least: false,
    def: "confidently aggressive or self-assured; positive: aggressive; dogmatic"
  },
  {
    adj: "attractive",
    value: "I",
    most: false,
    least: false,
    def: "providing pleasure or delight, especially in appearance or manner; pleasing; charming; alluring"
  },
  {
    adj: "bold",
    value: "D",
    most: false,
    least: false,
    def: "not hesitating or fearful in the face of actual or possible danger or rebuff; courageous and daring"
  },
  {
    adj: "brave",
    value: "D",
    most: false,
    least: false,
    def: "possessing or exhibiting courage or courageous endurance"
  },
  {
    adj: "calm",
    value: "S",
    most: false,
    least: false,
    def: "free from excitement or passion; tranquil"
  },
  {
    adj: "captivating",
    value: "I",
    most: false,
    least: false,
    def: "to attract and hold the attention or interest of, as by beauty or excellence; enchant"
  },
  {
    adj: "careful ",
    value: "C",
    most: false,
    least: false,
    def: "cautious in one's actions; (of things) done or performed with accuracy or caution"
  },
  {
    adj: "cautious",
    value: "C",
    most: false,
    least: false,
    def: "showing, using, or characterized by caution"
  },
  {
    adj: "charismatic",
    value: "I",
    most: false,
    least: false,
    def: "of, having, or characteristic of charisma; charisma: a spiritual power or personal quality that gives an individual influence or authority over large numbers of people"
  },
  {
    adj: "charming",
    value: "I",
    most: false,
    least: false,
    def: "pleasing; delightful"
  },
  {
    adj: "cheerful",
    value: "I",
    most: false,
    least: false,
    def: "characterized by or expressive of good spirits or cheerfulness"
  },
  {
    adj: "cheery",
    value: "I",
    most: false,
    least: false,
    def: "in good spirits; cheerful; happy"
  },
  {
    adj: "civil",
    value: "C",
    most: false,
    least: false,
    def: "courteous and polite"
  },
  {
    adj: "competitive",
    value: "D",
    most: false,
    least: false,
    def: "having a strong desire to compete or to succeed"
  },
  {
    adj: "complacent",
    value: "S",
    most: false,
    least: false,
    def: "pleased, especially with oneself or one's merits, advantages, situation, etc., often without awareness ofsome potential danger or defect; self-satisfied"
  },
  {
    adj: "compliant",
    value: "C",
    most: false,
    least: false,
    def: "complying; obeying, obliging, or yielding"
  },
  {
    adj: "composed",
    value: "S",
    most: false,
    least: false,
    def: "calm; tranquil; serene"
  },
  {
    adj: "confident",
    value: "I",
    most: false,
    least: false,
    def: "having strong belief or full assurance; sure"
  },
  {
    adj: "conscientious",
    value: "C",
    most: false,
    least: false,
    def: "careful and painstaking; particular; meticulous; scrupulous"
  },
  {
    adj: "considerate",
    value: "S",
    most: false,
    least: false,
    def: "showing kindly awareness or regard for another's feelings, circumstances, etc."
  },
  {
    adj: "contented",
    value: "S",
    most: false,
    least: false,
    def: "satisfied; content"
  },
  {
    adj: "controlled",
    value: "C",
    most: false,
    least: false,
    def: "carefully regulated, tested, or verified; held in check; curbed"
  },
  {
    adj: "conventional",
    value: "S",
    most: false,
    least: false,
    def: "conforming or adhering to accepted standards, as of conduct or taste; ordinary rather than different or original"
  },
  {
    adj: "convincing",
    value: "I",
    most: false,
    least: false,
    def: "persuading or assuring by argument or evidence"
  },
  {
    adj: "cooperative",
    value: "S",
    most: false,
    least: false,
    def: "working or acting together willingly for a common purpose or benefit"
  },
  {
    adj: "courteous",
    value: "C",
    most: false,
    least: false,
    def: "having or showing good manners; polite"
  },
  {
    adj: "daring",
    value: "D",
    most: false,
    least: false,
    def: "bold or courageous; fearless or intrepid; adventurous"
  },
  {
    adj: "decisive",
    value: "D",
    most: false,
    least: false,
    def: "characterized by or displaying no or little hesitation; resolute; determined"
  },
  {
    adj: "demanding",
    value: "D",
    most: false,
    least: false,
    def: "calling for intensive effort or attention; taxing"
  },
  {
    adj: "detailed",
    value: "C",
    most: false,
    least: false,
    def: "having many details; thorough in the treatment of details; minute"
  },
  {
    adj: "determined",
    value: "D",
    most: false,
    least: false,
    def: "resolute; staunch; decided; settled; resolved"
  },
  {
    adj: "diplomatic",
    value: "C",
    most: false,
    least: false,
    def: "skilled in dealing with sensitive matters or people; tactful"
  },
  {
    adj: "direct",
    value: "D",
    most: false,
    least: false,
    def: "straightforward; frank; candid; absolute; exact"
  },
  {
    adj: "dogged",
    value: "D",
    most: false,
    least: false,
    def: "persistent in effort; stubbornly tenacious"
  },
  {
    adj: "dominant",
    value: "D",
    most: false,
    least: false,
    def: "ruling, governing, or controlling; having or exerting authority or influence"
  },
  {
    adj: "domineering",
    value: "D",
    most: false,
    least: false,
    def: "inclined to rule arbitrarily or despotically; overbearing; tyrannical"
  },
  {
    adj: "eager",
    value: "D",
    most: false,
    least: false,
    def: "keen or ardent in desire or feeling; impatiently longing; wanting to do or have something very much"
  },
  {
    adj: "easygoing",
    value: "S",
    most: false,
    least: false,
    def: "calm and unworried; relaxed and rather casual"
  },
  {
    adj: "egocentric",
    value: "D",
    most: false,
    least: false,
    def: "having little or no regard for interests, beliefs, or attitudes other than one's own; self-centered"
  },
  {
    adj: "eloquent",
    value: "I",
    most: false,
    least: false,
    def: "having or exercising the power of fluent, forceful, and appropriate speech"
  },
  {
    adj: "enthusiastic",
    value: "I",
    most: false,
    least: false,
    def: "full of or characterized by enthusiasm; ardent"
  },
  {
    adj: "even-tempered",
    value: "S",
    most: false,
    least: false,
    def: "not easily ruffled, annoyed, or disturbed; calm"
  },
  {
    adj: "exact",
    value: "C",
    most: false,
    least: false,
    def: "strictly accurate or correct; precise, as opposed to approximate"
  },
  {
    adj: "expressive",
    value: "I",
    most: false,
    least: false,
    def: "full of expression; meaningful"
  },
  {
    adj: "extroverted",
    value: "I",
    most: false,
    least: false,
    def: "an outgoing, overtly expressive person"
  },
  {
    adj: "fearful",
    value: "C",
    most: false,
    least: false,
    def: "feeling fear, dread, apprehension, or solicitude"
  },
  {
    adj: "firm",
    value: "D",
    most: false,
    least: false,
    def: "steadfast or unwavering, as persons or principles"
  },
  {
    adj: "flexible",
    value: "S",
    most: false,
    least: false,
    def: "willing or disposed to yield; pliable"
  },
  {
    adj: "forceful",
    value: "D",
    most: false,
    least: false,
    def: "full of force; powerful; vigorous; effective"
  },
  {
    adj: "friendly",
    value: "I",
    most: false,
    least: false,
    def: "like a friend; kind; helpful"
  },
  {
    adj: "fussy",
    value: "C",
    most: false,
    least: false,
    def: "hard to satisfy or please"
  },
  {
    adj: "generous",
    value: "S",
    most: false,
    least: false,
    def: "liberal in giving or sharing; unselfish"
  },
  {
    adj: "gentle",
    value: "S",
    most: false,
    least: false,
    def: "kindly; amiable"
  },
  {
    adj: "good mixer",
    value: "I",
    most: false,
    least: false,
    def: "highly sociable; gets along with everyone"
  },
  {
    adj: "good-natured",
    value: "S",
    most: false,
    least: false,
    def: "having or showing a pleasant, kindly disposition; amiable"
  },
  {
    adj: "headstrong",
    value: "D",
    most: false,
    least: false,
    def: "determined to have one's own way; willful; stubborn; obstinate"
  },
  {
    adj: "helpful",
    value: "S",
    most: false,
    least: false,
    def: "giving or rendering aid or assistance; of service"
  },
  {
    adj: "high-spirited",
    value: "I",
    most: false,
    least: false,
    def: "characterized by energetic enthusiasm, elation, vivacity, etc"
  },
  {
    adj: "humble",
    value: "C",
    most: false,
    least: false,
    def: "not proud or arrogant; modest"
  },
  {
    adj: "impartial",
    value: "C",
    most: false,
    least: false,
    def: "not partial or biased; fair; just"
  },
  {
    adj: "impatient",
    value: "D",
    most: false,
    least: false,
    def: "not patient; not accepting delay, opposition, pain, etc."
  },
  {
    adj: "impulsive",
    value: "I",
    most: false,
    least: false,
    def: "acting or done without forethought"
  },
  {
    adj: "independent",
    value: "D",
    most: false,
    least: false,
    def: "not influenced or controlled by others in matters of opinion, conduct, etc.; thinking or acting for oneself"
  },
  {
    adj: "influential",
    value: "I",
    most: false,
    least: false,
    def: "having or exerting influence, especially great influence"
  },
  {
    adj: "inquisitive",
    value: "D",
    most: false,
    least: false,
    def: "given to inquiry, research, or asking questions; eager for knowledge; intellectually curious"
  },
  {
    adj: "insightful",
    value: "C",
    most: false,
    least: false,
    def: "characterized by or displaying insight; perceptive"
  },
  {
    adj: "insistent",
    value: "D",
    most: false,
    least: false,
    def: "earnest or emphatic in dwelling upon, maintaining, or demanding something; persistent; pertinacious"
  },
  {
    adj: "inspiring",
    value: "I",
    most: false,
    least: false,
    def: "having the effect of inspiring someone; inspire: fill (someone) with the urge or ability to do or feel something, especially to do something creative"
  },
  {
    adj: "introspective",
    value: "C",
    most: false,
    least: false,
    def: "characterized by introspection, the act or process of looking into oneself"
  },
  {
    adj: "introverted",
    value: "C",
    most: false,
    least: false,
    def: "a shy person"
  },
  {
    adj: "inviting",
    value: "I",
    most: false,
    least: false,
    def: "attractive, alluring, or tempting"
  },
  {
    adj: "jovial",
    value: "I",
    most: false,
    least: false,
    def: "endowed with or characterized by a hearty, joyous humor or a spirit of good-fellowship"
  },
  {
    adj: "joyful ",
    value: "I",
    most: false,
    least: false,
    def: "full of joy, as a person or one's heart; glad; delighted"
  },
  {
    adj: "kind",
    value: "S",
    most: false,
    least: false,
    def: "of a good or benevolent nature or disposition, as a person"
  },
  {
    adj: "leading",
    value: "I",
    most: false,
    least: false,
    def: "directing, guiding"
  },
  {
    adj: "lenient",
    value: "S",
    most: false,
    least: false,
    def: "agreeably tolerant; permissive; indulgent"
  },
  {
    adj: "lighthearted",
    value: "I",
    most: false,
    least: false,
    def: "carefree; cheerful; merry"
  },
  {
    adj: "logical",
    value: "C",
    most: false,
    least: false,
    def: "reasonable; to be expected; reasoning in accordance with the principles of logic, as a person or the mind"
  },
  {
    adj: "loyal",
    value: "S",
    most: false,
    least: false,
    def: "faithful to any leader, party, or cause, or to any person or thing conceived as deserving fidelity"
  },
  {
    adj: "magnetic",
    value: "I",
    most: false,
    least: false,
    def: "exerting a strong attractive power or charm"
  },
  {
    adj: "moderate",
    value: "S",
    most: false,
    least: false,
    def: "kept or keeping within reasonable or proper limits; not extreme, excessive, or intense"
  },
  {
    adj: "modest",
    value: "S",
    most: false,
    least: false,
    def: "having or showing a moderate or humble estimate of one's merits, importance, etc.; free from vanity,egotism, boastfulness, or great pretensions"
  },
  {
    adj: "neighborly",
    value: "S",
    most: false,
    least: false,
    def: "having or showing qualities befitting a neighbor; friendly"
  },
  {
    adj: "nonchalant",
    value: "S",
    most: false,
    least: false,
    def: "coolly unconcerned, indifferent, or unexcited; casual"
  },
  {
    adj: "obedient",
    value: "S",
    most: false,
    least: false,
    def: "obeying or willing to obey; complying with or submissive to authority"
  },
  {
    adj: "obliging",
    value: "S",
    most: false,
    least: false,
    def: "willing or eager to do favors, offer one's services, etc.; accommodating"
  },
  {
    adj: "observant",
    value: "C",
    most: false,
    least: false,
    def: "quick to notice or perceive; alert"
  },
  {
    adj: "optimistic",
    value: "I",
    most: false,
    least: false,
    def: "disposed to take a favorable view of events or conditions and to expect the most favorable outcome"
  },
  {
    adj: "original",
    value: "D",
    most: false,
    least: false,
    def: "new; fresh; inventive; novel"
  },
  {
    adj: "outgoing",
    value: "I",
    most: false,
    least: false,
    def: "interested in and responsive to others; friendly; sociable"
  },
  {
    adj: "outspoken",
    value: "D",
    most: false,
    least: false,
    def: "free or unreserved in speech"
  },
  {
    adj: "passive",
    value: "S",
    most: false,
    least: false,
    def: "not participating readily or actively; inactive; not reacting visibly to something that might be expected to produce manifestations of an emotion or feeling"
  },
  {
    adj: "patient",
    value: "S",
    most: false,
    least: false,
    def: "quietly and steadily persevering or diligent, especially in detail or exactness"
  },
  {
    adj: "perceptive",
    value: "C",
    most: false,
    least: false,
    def: "having or showing keenness of insight, understanding, or intuition"
  },
  {
    adj: "perfectionist",
    value: "C",
    most: false,
    least: false,
    def: "of, relating to, or distinguished by perfection or perfectionism; perfection: the highest degree of proficiency, skill, or excellence, as in some art"
  },
  {
    adj: "persistent",
    value: "D",
    most: false,
    least: false,
    def: "lasting or enduring tenaciously; constantly repeated; continued"
  },
  {
    adj: "persuasive",
    value: "I",
    most: false,
    least: false,
    def: "able, fitted, or intended to persuade; persuade: to prevail on (a person) to do something, as by advising or urging"
  },
  {
    adj: "pioneering",
    value: "D",
    most: false,
    least: false,
    def: "being the earliest, original, first of a particular kind, etc."
  },
  {
    adj: "playful",
    value: "I",
    most: false,
    least: false,
    def: "full of play or fun; sportive; frolicsome"
  },
  {
    adj: "poised",
    value: "I",
    most: false,
    least: false,
    def: "(of a person) composed, dignified, and self-assured"
  },
  {
    adj: "polite",
    value: "C",
    most: false,
    least: false,
    def: "showing good manners toward others, as in behavior, speech, etc.; courteous; civil"
  },
  {
    adj: "precise",
    value: "C",
    most: false,
    least: false,
    def: "definite or exact in statement; excessively or rigidly particular"
  },
  {
    adj: "predictable",
    value: "S",
    most: false,
    least: false,
    def: "expected, especially on the basis of previous or known behavior"
  },
  {
    adj: "private",
    value: "C",
    most: false,
    least: false,
    def: "preferring privacy; retiring; solitary; secluded"
  },
  {
    adj: "refined",
    value: "C",
    most: false,
    least: false,
    def: "having or showing well-bred feeling, taste, etc."
  },
  {
    adj: "relaxed",
    value: "S",
    most: false,
    least: false,
    def: "being free of or relieved from tension or anxiety"
  },
  {
    adj: "reserved",
    value: "C",
    most: false,
    least: false,
    def: "formal or self-restrained in manner and relationship; avoiding familiarity or intimacy with others"
  },
  {
    adj: "respectful",
    value: "C",
    most: false,
    least: false,
    def: "full of, characterized by, or showing politeness or deference"
  },
  {
    adj: "responsive",
    value: "S",
    most: false,
    least: false,
    def: "reacting quickly and positively"
  },
  {
    adj: "restless",
    value: "D",
    most: false,
    least: false,
    def: "characterized by or showing inability to remain at rest"
  },
  {
    adj: "risk-taker",
    value: "D",
    most: false,
    least: false,
    def: "a person or corporation inclined to take risks"
  },
  {
    adj: "satisfied",
    value: "S",
    most: false,
    least: false,
    def: "content; pleased"
  },
  {
    adj: "self-promoting",
    value: "I",
    most: false,
    least: false,
    def: "Promoting or publicizing oneself or one's activities, especially in a forceful way"
  },
  {
    adj: "self-reliant",
    value: "D",
    most: false,
    least: false,
    def: "relying on oneself or on one's own powers, resources, etc."
  },
  {
    adj: "serene",
    value: "S",
    most: false,
    least: false,
    def: "calm, peaceful, or tranquil; unruffled"
  },
  {
    adj: "sociable",
    value: "I",
    most: false,
    least: false,
    def: "inclined to associate with or be in the company of others; friendly or agreeable in company; companionable"
  },
  {
    adj: "soft-spoken",
    value: "C",
    most: false,
    least: false,
    def: "speaking with a soft or gentle voice; mild"
  },
  {
    adj: "stimulating",
    value: "I",
    most: false,
    least: false,
    def: "to rouse to action or effort, as by encouragement or pressure; spur on; incite"
  },
  {
    adj: "strong-willed",
    value: "D",
    most: false,
    least: false,
    def: "having a powerful will; resolute; stubborn; obstinate"
  },
  {
    adj: "stubborn",
    value: "D",
    most: false,
    least: false,
    def: "unreasonably obstinate; obstinately unmoving; fixed or set in purpose or opinion; resolute"
  },
  {
    adj: "submissive",
    value: "S",
    most: false,
    least: false,
    def: "inclined or ready to submit or yield to the authority of another; unresistingly or humbly obedient"
  },
  {
    adj: "sympathetic",
    value: "S",
    most: false,
    least: false,
    def: "characterized by, proceeding from, exhibiting, or feeling sympathy; sympathizing; compassionate"
  },
  {
    adj: "systematic",
    value: "C",
    most: false,
    least: false,
    def: "given to or using a system or method; methodical"
  },
  {
    adj: "tactful",
    value: "C",
    most: false,
    least: false,
    def: "having or manifesting tact"
  },
  {
    adj: "talkative",
    value: "I",
    most: false,
    least: false,
    def: "inclined to talk a great deal"
  },
  {
    adj: "thorough",
    value: "C",
    most: false,
    least: false,
    def: "extremely attentive to accuracy and detail; painstaking"
  },
  {
    adj: "timid",
    value: "C",
    most: false,
    least: false,
    def: "lacking in self-assurance, courage, or bravery; easily alarmed; timorous; shy"
  },
  {
    adj: "unbiased",
    value: "C",
    most: false,
    least: false,
    def: "not biased or prejudiced; fair; impartial"
  },
  {
    adj: "vigorous",
    value: "D",
    most: false,
    least: false,
    def: "strong; active; robust; energetic; forceful"
  },
  {
    adj: "well-disciplined",
    value: "C",
    most: false,
    least: false,
    def: "having or exhibiting discipline; rigorous"
  },
  {
    adj: "willing",
    value: "S",
    most: false,
    least: false,
    def: "cheerfully consenting or ready"
  }
]