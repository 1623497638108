import { push } from "connected-react-router";
import Alert from "react-s-alert";
import * as routes from "../../Router/constants/routes";
import { db } from "../../config/auth/Firebase";
import {
  STORING_USER_DATA,
  SUCCESSFUL_SURVEY_STORAGE,
  CLEAR_STORAGE_ERROR,
  FAILURE_SURVEY_STORAGE,
  CLEAR_DATA
} from "./types";

import moment from "moment";

export const submitSurvey = (user_full_name, user_email_one, user_array, user_total_d, user_total_i, user_total_s, user_total_c) => {
  return dispatch => {
    db.collection("Survey_Submission_Data").doc(user_email_one).set({
      JSON_Data_Array: user_array,
      Submission_Date: moment().format().toString(),
      Total_C: user_total_c,
      Total_D: user_total_d,
      Total_I: user_total_i,
      Total_S: user_total_s,
      User_Email: user_email_one,
      User_Name: user_full_name
    })
      .then(function () {
        dispatch({ type: SUCCESSFUL_SURVEY_STORAGE });
        dispatch(push(routes.COMPLETION));
      })
      .catch(err => {
        surveyStorageFail(dispatch, err.message);
        Alert.error(`${err.message}`, {
          effect: "scale",
          onClose: function (e) {
            setTimeout(() => {
              dispatch({ type: CLEAR_STORAGE_ERROR });
            });
          }
        });
      });
  }
}

//handles loading spinner while user submits data
export const startLoader = () => {
  return dispatch => {
    dispatch({
      type: STORING_USER_DATA
    });
  }
}

export const clearData = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_DATA
    });
  }
}

const surveyStorageFail = (dispatch, err) => {
  dispatch({
    type: FAILURE_SURVEY_STORAGE,
    payload: err
  });
};

