import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

//Dev Environment used until App goes Live
const devConfig = {
  apiKey: "AIzaSyDfBsO4yfFMjNMbUOT5ZGmWk_O69HqutBc",
  authDomain: "flux-metrics-dev.firebaseapp.com",
  databaseURL: "https://flux-metrics-dev.firebaseio.com",
  projectId: "flux-metrics-dev",
  storageBucket: "flux-metrics-dev.appspot.com",
  messagingSenderId: "840457179316"
};

// const prodConfig = {
//   apiKey: "AIzaSyAGOL3UCZSbhwkLWS9yZixfXJNUHUz1xbA",
//   authDomain: "flux-metrics-prod.firebaseapp.com",
//   databaseURL: "https://flux-metrics-prod.firebaseio.com",
//   projectId: "flux-metrics-prod",
//   storageBucket: "flux-metrics-prod.appspot.com",
//   messagingSenderId: "81752500958"
// };

firebase.initializeApp(devConfig);

export const auth = firebase.auth();

export const db = firebase.firestore();

export const fs_settings = {
  timestampsInSnapshots: true
};

db.settings(fs_settings);
