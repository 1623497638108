import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Responsive from 'react-responsive';
import Alert from "react-s-alert";
import data from '../../data/Psychometric_Pairs'
import { submitSurvey, startLoader } from '../../store/actions/SurveyActions'
import RadioButton from 'grommet/components/RadioButton';
import FormField from 'grommet/components/FormField';
import Button from 'grommet/components/Button';
import Headline from 'grommet/components/Headline';
import Spinning from 'grommet/components/icons/Spinning';
import { UncontrolledTooltip } from 'reactstrap';


const most = "most"
const least = "least"
var type_d = 0;
var type_i = 0;
var type_s = 0;
var type_c = 0;


/*** Logs for Testing ***/
// console.log("d: ", type_d)
// console.log("i: ", type_i)
// console.log("s: ", type_s)
// console.log("c: ", type_c)


class Survey extends Component {

  constructor(props) {
    super(props);
    this.surveyReset();
  }

  surveyReset() {
    type_d = 0;
    type_i = 0;
    type_s = 0;
    type_c = 0;
    data.forEach(function (item) {
      item.most = false;
      item.least = false;
    });
  }

  onButtonPress = e => {
    let count = 0;
    e.preventDefault();
    return new Promise((resolve, reject) => {
      data.forEach(function (item) {
        if (item.least === false && item.most === false) {

        } else {
          count = count + 1;
        }
      });
      resolve(count);
    }).then((res) => {
      if (res === 140) {
        this.props.startLoader();
        this.launchSubmission(data);
      } else {
        this.renderError();
      }
    })
  };

  renderError() {
    Alert.info('Please Answer All Questions Before Submitting.  It Seems You May Have Missed One or More Questions', {
      position: 'bottom-right',
      effect: 'scale'
    });
  }

  launchSubmission(data) {
    return new Promise((resolve, reject) => {
      data.forEach(function (item) {
        if (item.value === "D" && item.least === false) {
          type_d = type_d + 1;
        } else if (item.value === "D" && item.least === true) {
          type_d = type_d - 1;
        } else if (item.value === "I" && item.least === false) {
          type_i = type_i + 1;
        } else if (item.value === "I" && item.least === true) {
          type_i = type_i - 1;
        } else if (item.value === "S" && item.least === false) {
          type_s = type_s + 1;
        } else if (item.value === "S" && item.least === true) {
          type_s = type_s - 1;
        } else if (item.value === "C" && item.least === false) {
          type_c = type_c + 1;
        } else if (item.value === "C" && item.least === true) {
          type_c = type_c - 1;
        }
      });
      resolve(true);
    }).then((res) => {
      if (res) {
        this.props.submitSurvey(this.props.user_full_name, this.props.user_email_one, data, type_d, type_i, type_s, type_c)
      }
    })
  }

  onRadioClick = (index, value, type) => {
    if (value === "most" && type === "D") {
      data[index].most = true;
      data[index].least = false;
    } else if (value === "least" && type === "D") {
      data[index].most = false;
      data[index].least = true;
    } else if (value === "most" && type === "I") {
      data[index].most = true;
      data[index].least = false;
    } else if (value === "least" && type === "I") {
      data[index].most = false;
      data[index].least = true;
    } else if (value === "most" && type === "S") {
      data[index].most = true;
      data[index].least = false;
    } else if (value === "least" && type === "S") {
      data[index].most = false;
      data[index].least = true;
    } else if (value === "most" && type === "C") {
      data[index].most = true;
      data[index].least = false;
    } else if (value === "least" && type === "C") {
      data[index].most = false;
      data[index].least = true;
    }
  };

  render() {
    const Mobile = props => <Responsive {...props} maxWidth={767} />;
    const Default = props => <Responsive {...props} minWidth={768} />;
    return (
      <div>
        <Default>
          <div>
            {this.props.loading ? (
              <div className="submit-container">
                <Headline className="submit-message animated pulse">Submitting Data</Headline>
                <Spinning className="submit-spinner" size="large" />
              </div>
            ) : (
                <div className="survey-form">
                  <div className="survey-spacer">
                    <div className="animated infinite pulse">
                      <span className="hover-span">* Hover Adjective For Definition Refresher</span>
                    </div>
                    <Headline className="survey-title animated rubberBand">Flux Metrics</Headline>
                  </div>
                  {/* <div className="animated infinite pulse">
                    
                  </div> */}
                  <hr className="header-divide" />
                  {data.sort(function (a, b) { return 0.5 - Math.random() }).map((item, i) => <div className="question-form animated rubberBand" key={i}>
                    <span className="survey-adj" id={`UncontrolledTooltipExample_${i}`}>{item.adj}</span>
                    <UncontrolledTooltip placement="bottom" target={`UncontrolledTooltipExample_${i}`}>{item.def}
                    </UncontrolledTooltip>
                    <FormField>
                      <RadioButton
                        id={`${item.adj}-1`}
                        name={item.adj}
                        label='Most Like You'
                        onChange={this.onRadioClick.bind(this, i, most, item.value)}
                      />
                      <RadioButton
                        id={`${item.adj}-2`}
                        name={item.adj}
                        label='Least Like You'
                        onChange={this.onRadioClick.bind(this, i, least, item.value)}
                      />
                    </FormField>
                  </div>)}
                  <Button className="Survey-Submit-Button" label='Submit Survey'
                    primary={true}
                    onClick={this.onButtonPress.bind(this)}
                  />
                </div>
              )}
            <Alert stack={true} />
          </div>
        </Default>
        <Mobile>
          <div>
            {this.props.loading ? (
              <div className="submit-container">
                <Headline className="submit-message animated pulse">Submitting Data</Headline>
                <Spinning className="submit-spinner" size="large" />
              </div>
            ) : (
                <div className="survey-form">
                  <div className="survey-spacer">
                    <Headline className="survey-title animated rubberBand">Flux Metrics</Headline>
                    <div className="animated infinite pulse">
                      <span className="mobile-hover-span">* Tap Adjective For Definition Refresher</span>
                    </div>
                  </div>
                  <hr className="header-divide" />
                  {data.sort(function (a, b) { return 0.5 - Math.random() }).map((item, i) => <div className="question-form animated rubberBand" key={i}>
                    <span className="survey-adj animated infinite pulse" id={`UncontrolledTooltipExample_${i}`}>{item.adj}</span>
                    <UncontrolledTooltip className="mobile-tip" placement="bottom" target={`UncontrolledTooltipExample_${i}`}>{item.def}
                    </UncontrolledTooltip>
                    <FormField>
                      <RadioButton
                        id={`${item.adj}-1`}
                        name={item.adj}
                        label='Most Like You'
                        onChange={this.onRadioClick.bind(this, i, most, item.value)}
                      />
                      <RadioButton
                        id={`${item.adj}-2`}
                        name={item.adj}
                        label='Least Like You'
                        onChange={this.onRadioClick.bind(this, i, least, item.value)}
                      />
                    </FormField>
                  </div>)}
                  <Button className="Survey-Submit-Button" label='Submit Survey'
                    primary={true}
                    onClick={this.onButtonPress.bind(this)}
                  />
                </div>
              )}
            <Alert stack={true} />
          </div>
        </Mobile>
      </div>
    );
  }
}

const mapStateToProps = ({ user_id_form, user_survey }) => {
  const { user_email_one, user_email_two, user_full_name } = user_id_form;
  const { loading } = user_survey;

  return {
    user_email_one,
    user_email_two,
    user_full_name,
    loading
  };
};

const mapDispatchToProps = dispatch => ({
  submitSurvey: (user_full_name, user_email_one, user_array, user_total_d, user_total_i, user_total_s, user_total_c) => dispatch(submitSurvey(user_full_name, user_email_one, user_array, user_total_d, user_total_i, user_total_s, user_total_c)),
  startLoader: () => dispatch(startLoader())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Survey)
);

// import React from 'react';
// import { UncontrolledTooltip } from 'reactstrap';

// export default function Example() {
//   return (
//     <div>
//       <p>Somewhere in here is a <span style={{textDecoration: "underline", color:"blue"}} href="#" id="UncontrolledTooltipExample">tooltip</span>.</p>
//       <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
//         Hello world!
//       </UncontrolledTooltip>
//     </div>
//   );
// }