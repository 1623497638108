import React, { Component } from "react";
import {
  Router,
  Route,
  Redirect
} from "react-router-dom";
import { default as GrommetApp } from 'grommet/components/App';

// Routes/Navigation
import LandingPage from "../components/Landing/Landing";
import IdPage from "../components/Id_Acquisition/Id_Form";
import SurveyPage from '../components/Survey/Survey';
import DisclaimerPage from '../components/Disclaimer/Disclaimer';
import CompletionPage from '../components/Completion/Completion';
import * as routes from "./constants/routes";

//Css

//Private Route Components only accesible by User who has completed ID Data Form...Otherwise Redirected to ID Acquisition Page
const PrivateRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      user ? (
        <Component {...props} />
      ) : (
          <Redirect to={routes.ID_FORM} />
        )
    }
  />
);

//App class rendered from index.js wrapped in our store for Redux...see index.js for more understanding
class AppRouter extends Component {

  render() {
    return (
      <Router history={this.props.history}>
        <GrommetApp className="App-Main">
          <Route
            exact
            path={routes.LANDING}
            component={() => <LandingPage />}
          />
          <Route
            exact
            path={routes.ID_FORM}
            component={() => <IdPage />}
          />
          <PrivateRoute
            exact
            user={this.props.user}
            path={routes.SURVEY}
            component={() => <SurveyPage />}
          />
          <Route
            exact
            path={routes.DISCLAIMER}
            component={() => <DisclaimerPage />}
          />
          <PrivateRoute
            exact
            user={this.props.user}
            path={routes.COMPLETION}
            component={() => <CompletionPage />}
          />
        </GrommetApp>
      </Router>
    );
  }
};

export default AppRouter;
