import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Responsive from 'react-responsive';
import Button from 'grommet/components/Button';
import Headline from 'grommet/components/Headline';

class Disclaimer extends Component {
  onButtonPress = e => {
    e.preventDefault();
    this.props.history.push('/Id_Acquisition');
  };


  render() {
    const Mobile = props => <Responsive {...props} maxWidth={767} />;
    const Default = props => <Responsive {...props} minWidth={768} />;
    return (
      <div>
        <Default>
          <div>
            <Headline className="Disclaimer-Headline animated bounceInDown">Disclaimer</Headline>
            <Headline className="Disclaimer-Sub-Headline animated bounceInDown">Please note that even though we carefully and methodically analyze your survey submissions with a set of scientific standards, the results of your survey along with your issued personality description are strictly for educational and entertainment purposes only.  Your Privacy is very important to us and under no circumstances will your data ever be shared and is protected under a security blanket backed by Google.  We thank you for taking the time to complete this survey.  Your continual efforts help us determine the best model and practices in analyzing and determining ones personality!</Headline>
            <div className="button-container animated bounceInUp">
              <Button className="Survey-Button" label='Finished'
                primary={true}
                onClick={this.onButtonPress.bind(this)}
              />
            </div>
          </div>
        </Default>
        <Mobile>
          <div>
            <Headline className="Disclaimer-Headline-Mobile animated bounceInDown">Disclaimer</Headline>
            <Headline className="Disclaimer-Sub-Headline-Mobile animated bounceInDown">Please note that even though we carefully and methodically analyze your survey submissions with a set of scientific standards, the results of your survey along with your issued personality description are strictly for educational and entertainment purposes only.  Your Privacy is very important to us and under no circumstances will your data ever be shared and is protected under a security blanket backed by Google.  We thank you for taking the time to complete this survey.  Your continual efforts help us determine the best model and practices in analyzing and determining ones personality!</Headline>
            <div className="button-container animated bounceInUp">
              <Button className="Survey-Button" label='Finished'
                primary={true}
                onClick={this.onButtonPress.bind(this)}
              />
            </div>
          </div>
        </Mobile>
      </div>
    )
  }
}

export default withRouter(Disclaimer);
