import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Responsive from 'react-responsive';
import Button from 'grommet/components/Button';
import Headline from 'grommet/components/Headline';
import { clearData } from '../../store/actions/SurveyActions';

class Completion extends Component {
  onButtonPress = e => {
    e.preventDefault();
    this.props.history.push('/');
  };


  render() {
    const Mobile = props => <Responsive {...props} maxWidth={767} />;
    const Default = props => <Responsive {...props} minWidth={768} />;
    return (
      <div>
        <Default>
          <div>
            <Headline className="Completion-Headline animated bounceInDown">Survey Completed!</Headline>
            <Headline className="Completion-Sub-Headline animated bounceInDown">Thank You For Completing The Flux-Metrics Personality Survey!</Headline>
            <Headline className="Completion-Sub-Headline  animated bounceInDown">The Results Of Your Survey Will Be Carefully Examined And You Will Be Issued A Personality Description Sent To The Email Address You Have Provided To Us Pre-Survey Completion Within 24 Hours.  We are Working Hard To Perfect This Survey And Plan On Having Results Immediately Issued In The Very Near Future!  In The Meantime Thank You For Your Patience!  </Headline>
            <div className="button-container animated bounceInUp">
              <Button className="Survey-Button" label='Finished'
                primary={true}
                onClick={this.onButtonPress.bind(this)}
              />
            </div>
          </div>
        </Default>
        <Mobile>
          <div>
            <Headline className="Completion-Headline-Mobile animated bounceInDown">Survey Completed!</Headline>
            <Headline className="Completion-Sub-Headline-Mobile animated bounceInDown">Thank You For Completing The Flux-Metrics Personality Survey!</Headline>
            <Headline className="Completion-Sub-Headline-Mobile animated bounceInDown">The Results Of Your Survey Will Be Carefully Examined And You Will Be Issued A Personality Description Sent To The Email Address You Have Provided To Us Pre-Survey Completion Within 24 Hours.  We are Working Hard To Perfect This Survey And Plan On Having Results Immediately Issued In The Very Near Future!  In The Meantime Thank You For Your Patience!  </Headline>
            <div className="button-container animated bounceInUp">
              <Button className="Survey-Button" label='Finished'
                primary={true}
                onClick={this.onButtonPress.bind(this)}
              />
            </div>
          </div>
        </Mobile>
      </div>
    )
  }
}

const mapStateToProps = ({ user_survey }) => {
  const { loading } = user_survey;

  return {
    loading
  };
};

const mapDispatchToProps = dispatch => ({
  clearData: () => dispatch(clearData())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Completion)
);
