import {
  STORING_USER_DATA,
  SUCCESSFUL_SURVEY_STORAGE,
  FAILURE_SURVEY_STORAGE,
  CLEAR_STORAGE_ERROR
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  error: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORING_USER_DATA:
      return {
        ...state,
        loading: true,
      };
    case SUCCESSFUL_SURVEY_STORAGE:
      return {
        ...state,
        loading: false
      };
    case FAILURE_SURVEY_STORAGE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CLEAR_STORAGE_ERROR:
      return {
        ...state,
        error: ""
      };
    default:
      return state;
  }
};
