import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware, connectRouter } from "connected-react-router";
import ReduxThunk from "redux-thunk";
import reducers from "./store/reducers/index";
import './scss/index.css';
import '../node_modules/grommet-css'

const history = createBrowserHistory();

const store = createStore(
  connectRouter(history)(reducers),
  {},
  compose(applyMiddleware(ReduxThunk, routerMiddleware(history)))
);

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
);
