import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Responsive from 'react-responsive';
import Headline from 'grommet/components/Headline'
import Button from 'grommet/components/Button';

class Landing extends Component {

  onButtonPress = e => {
    e.preventDefault();
    this.props.history.push('/Id_Acquisition');
  };


  render() {
    const Mobile = props => <Responsive {...props} maxWidth={767} />;
    const Default = props => <Responsive {...props} minWidth={768} />;
    return (
      <div>
        <Default>
          <div>
            <Headline className="App-Headline animated bounceInDown">Flux Metrics</Headline>
            <Headline className="App-Sub-Headline animated bounceInDown">Who Are You Really?</Headline>
            <div className="button-container animated bounceInUp">
              <Button className="Survey-Button" label='Lets Find Out!'
                primary={true}
                onClick={this.onButtonPress.bind(this)}
              />
            </div>
          </div>
        </Default>
        <Mobile>
          <div>
            <Headline className="App-Headline-Mobile animated bounceInDown">Flux Metrics</Headline>
            <Headline className="App-Sub-Headline-Mobile animated bounceInDown">Who Are You Really?</Headline>
            <div className="button-container animated bounceInUp">
              <Button className="Survey-Button" label='Lets Find Out!'
                primary={true}
                onClick={this.onButtonPress.bind(this)}
              />
            </div>
          </div>
        </Mobile>
      </div>
    )
  }
}

export default withRouter(Landing);