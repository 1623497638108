import React, { Component } from 'react';
import { connect } from "react-redux";
import MyRouter from './Router/Router'

//Css
import './scss/App.css';
import './scss/animate.css';
import "react-s-alert/dist/s-alert-css-effects/scale.css";
import "react-s-alert/dist/s-alert-default.css";


class App extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <MyRouter history={this.props.history} user={this.props.user} />
    );
  }
}

const mapStateToProps = ({ user_id_form }) => {
  const { user } = user_id_form;
  return { user };
};

export default connect(mapStateToProps)(App);
