import {
  USER_EMAIL_ONE,
  USER_EMAIL_TWO,
  USER_FULL_NAME,
  CLEAR_DATA,
  GOT_USER
} from "../actions/types";

const INITIAL_STATE = {
  user_email_one: "",
  user_email_two: "",
  user_full_name: "",
  user: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_EMAIL_ONE:
      return {
        ...state,
        user_email_one: action.payload
      };
    case USER_EMAIL_TWO:
      return {
        ...state,
        user_email_two: action.payload
      };
    case USER_FULL_NAME:
      return {
        ...state,
        user_full_name: action.payload
      };
    case CLEAR_DATA:
      return {
        ...state,
        user_full_name: "",
        user_email_one: "",
        user_email_two: "",
        user: false
      };
    case GOT_USER:
      return {
        ...state,
        user: true
      };
    default:
      return state;
  }
};
